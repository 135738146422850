import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import AOS from "aos";
import "aos/dist/aos.css";

const WhyUs = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };       

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <VisibilitySensor onChange={onVisibilityChange}>
        <div className="flex flex-col items-center py-14 why-us-sec ">
          <h1 className="text-3xl font-bold md:mb-14 mb-8">Why Us ?</h1>

          <div
            className="md:flex space-y-10 md:space-y-0 justify-between md:w-2/3"
            
          >
            <div className="counter-item text-center">
              <h3 className="text-3xl font-bold mb-2">
                {isVisible && <CountUp end={10} duration={4} />}
                <span className="text-secondary-color"> </span> Years
              </h3>
              <p>of Delivering Innovative Solutions</p>
            </div>

            <div className="counter-item text-center">
              <h3 className="text-3xl font-bold mb-2">
                {isVisible && <CountUp end={450} duration={4} />}
                <span className="text-secondary-color">+</span> 
              </h3>
              <p>Corporate Clients</p>
            </div>

            <div className="counter-item text-center">
              <h3 className="text-3xl font-bold mb-2">
                {isVisible && <CountUp end={5000} duration={4} />}
                <span className="text-secondary-color">+</span>
              </h3>
              <p>Students Trained</p>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};

export default WhyUs;
