import './App.css';
import Home from './Pages/Home';
import About from './Pages/About';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { Routes, Route } from "react-router-dom";
import ProductDevelopment from './Pages/Services/ProductDevelopment';
import Contact from './Pages/Contact';
import TechnologyConsulting from './Pages/Services/TechnologyConsulting';
import Outsourcing from './Pages/Services/Outsourcing';
import Knime from './Pages/Services/Knime';
import MyExamo from './Pages/Platforms&Products/MyExamo';
import HLT from './Pages/Platforms&Products/HLT';
import VideoSystem from './Pages/Platforms&Products/VideoSystem';
import LMS from './Pages/Platforms&Products/LMS';
import ThinQuotientFranchise from './Pages/Offerings/ThinQuotientFranchise';
import ChanelPartner from './Pages/Offerings/ChanelPartner';
import SuccessStories from './Pages/Insights/SuccessStories';
import Webinars from './Pages/Insights/Webinars';
import Brochures from './Pages/Insights/Brochures';
import WhitePapers from './Pages/Insights/WhitePapers';
import CSR from './Pages/Insights/CSR';
import NewsandAnnouncements from './Pages/Insights/News&Announcements';
import BusinessDevelopment from './Pages/Career/BusinessDevelopment';
import TechnicalTrainer from './Pages/Career/TechnicalTrainer';
import Career from './Pages/Career/StudentDeveloperProgram';
import ScrollToTop from './Components/Common/ScrollToTop';
import ITFresher from './Pages/Career/ITFresher';
import KnimeNew from './KnimeNew/KnimeNew';
import KnimeVideos from './KnimeNew/knimeVideos';
import Campaign from './Pages/Campaign';
import ExpectationOfHiring from './Pages/Insights/ExpectationOfHiring';
import FreshersTraining from './Pages/Training/FreshersTraining';
import CorporateTraining from './Pages/Training/CorporateTraining';
import CampaignPage from './Pages/Training/CampaignPage';
import Tournaments from './Pages/Insights/Tournaments';
import GenerativeAIKNIMETraining from './Pages/Training/GenerativeAIKNIMETraining';
import WorkshopsPage from './Pages/WorkshopsPage';







function App() {
  return (
    <>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/services/productdevelopment" element={<ProductDevelopment />} />
        <Route path="/services/technologyconsulting" element={<TechnologyConsulting />} />
        <Route path="/services/outsourcing" element={<Outsourcing />} />
        <Route path="/services/knime" element={<Knime />} />
        <Route path="/platforms&products/myexamo" element={<MyExamo />} />
        <Route path="/platforms&products/hlt" element={<HLT />} />
        <Route path="/platforms&products/videosystem" element={<VideoSystem />} />
        <Route path="/platforms&products/lms" element={<LMS />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/offerings/thinquotient-franchise" element={<ThinQuotientFranchise />} />
        <Route path="/offerings/channel-partner" element={<ChanelPartner />} />
        <Route path="/insights/success-stories" element={<SuccessStories />} />
        <Route path="/insights/webinars" element={<Webinars />} />
        <Route path="/insights/brochures" element={<Brochures />} />
        <Route path="/insights/white-papers" element={<WhitePapers />} />
        <Route path="/insights/news-announcements" element={<NewsandAnnouncements />} />
        <Route path="/insights/csr" element={<CSR />} />
        <Route path="/careers/work-with-us" element={<Career />} />
        <Route path="/careers/ITFreshers" element={<ITFresher />} />
        <Route path="/careers/student-developer-program" element={<BusinessDevelopment />} />
        <Route path="/careers/apply-to-work" element={<BusinessDevelopment />} />
        <Route path="/careers/technical-trainer" element={<TechnicalTrainer />} />
        <Route path="/knimeInfo" element={<KnimeNew/>}/>
        <Route path="/knimeVideos" element={<KnimeVideos/>}/>
         {/* <Route path ="/training/opportunities" element={<Campaign/>}/>  */}
        
        <Route path ="/insights/expectationOfHiring" element={<ExpectationOfHiring/>}/>
        <Route path="/training/fresherstraining" element={<FreshersTraining />} />
        <Route path="/training/corporatetraining" element={<CorporateTraining />} />
        <Route path="/upskilling" element={<CampaignPage />} />
        <Route path="/insights/tournaments" element={<Tournaments />} />
        <Route path="/training/generativeAI-KNIMETraining" element={<GenerativeAIKNIMETraining />} />
         <Route path="/training/workshops" element={<WorkshopsPage />} /> 
      </Routes> 
    </>
  );
}

export default App;
