import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SuccessStoryCarousel from "../../Components/Home/SuccessStoryCarousel";
import StickyElements from "./StickyElements";
import StudentTestimonials from "./StudentTestimonials";
import Clientele from "./Clientele";
import FreshersBannerSection from "./FreshersBannerSection";
import ApiPath from "../../Components/Common/Apiurl";
import CallBackform from "./CallBackform";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .transform((value) => value.trim())
    .required("Name is required"),
  email: Yup.string()
    .transform((value) => value.trim())
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .transform((value) => value.trim())
    .matches(/^\d{10}$/, "Invalid phone number"),
  // .required("Phone is required"),
  message: Yup.string()
    .transform((value) => value.trim()),
  // .required("Message is required"),
  lookingFor: Yup.string()
    .transform((value) => value.trim())
    .required("Category is required"),
});

const CampaignPage = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);
   const [formVisible, setFormVisible] = useState(true);
  
  
    const [formError, setFormError] = useState('');
    const [errors, setErrors] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
    const [loading, setLoading] = useState(false);

  const pdfPaths = ["/assets/images/Revised Brochure2024.pdf"];

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };

  const location = useLocation();

  // Extract the query parameter value
  const searchParams = new URLSearchParams(location.search);
  const flag = searchParams.get('flag');
  useEffect(() => {
    document.title = "Campaign - Think Quotient";
    AOS.init();
  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get('scrollTo');

      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    // Rest of your useEffect logic
    console.log("------------->", flag);
    console.log(location.state);
  }, []);



  //  console.log("------------->",flag);
  //   const [formVisible, setFormVisible] = useState(true);

  //   const knimeParams = new URLSearchParams(location.search);
  //   const isFromKNIME = knimeParams.get('fromKNIME');
  //   console.log(isFromKNIME);

  //   const formik = useFormik({
  //   initialValues: {
  //     queryPageIndex: "CAMPAIGN",
  //     name: "",
  //     email: "",
  //     phone: "",
  //     message: "",
  //     lookingFor: "Internship Program",
  //   },

  //     validationSchema: validationSchema,
  //     onSubmit: async (values) => {

  //       const apiEndpoint =
  //         ApiPath + "/tqenquiry";

  //       try {
  //         const response = await fetch(apiEndpoint, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(values),
  //         });

  //         if (response.ok) {
  //           formik.resetForm();
  //           setFormVisible(false);
  //           setTimeout(() => {
  //             setFormVisible(true);
  //           }, 3000);
  //         }
  //       } catch (error) {
  //         console.error("Error submitting the form", error);
  //       }
  //     },
  //   });


  // const handleFormVisibilityChange = () => {
  //   setFormVisible(!formVisible);
  // };

  const pageTitle = "Campaign - ThinkQuotient";
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();



  useEffect(() => {

    AOS.init();

  }, []);

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;

    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split("?"); // Remove the leading '#/' and split at '?'

    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get("scrollTo");
      console.log("hello " + scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }

    // Rest of your useEffect logic
  }, []);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = React.useState("tab1");
  const [tabIndex, setTabIndex] = React.useState(0);
  const tabRef = useRef(null); // Create a ref for the tab content
      
 
  const data = [
    {
      count: "01",
      label: "Training by Industry Experts with Placement Assistance",
      value: "tab1",
      image: "",
      desc: ` `,
    },

    {
      count: "02",
      label: "Focus on Hands-on Practice",
      value: "tab2",
      image: "",
      desc: ``,
    },
    {
      count: "03",
      label: "Exposure to Live Projects",
      image: "",
      value: "tab3",
      desc: ``,
    },
    {
      count: "04",
      label: "Grooming for interview preparation",
      image: "",
      value: "tab4",
      desc: ``,
    },
    {
      count: "05",
      label: "2000+ Students Succesfully Placed",
      value: "tab5",
      image: "",
      desc: ``,
    },
    {
      count: "06",
      label: "1 year Support for Placement Opportunities",
      value: "tab6",
      image: "",
      desc: ``,
    },
  ];

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formPayload = {
     
      
      name: e.target.firstname.value,
      email: e.target.email.value.trim(),
      phone: e.target.phone.value,
      message: e.target.message.value.trim(),
      lookingFor: "Training",
      queryPageIndex: "CAMPAIGN",
    };
  
    let formIsValid = true;
    const newErrors = {
      name: '',
      email: '',
      phone: '',
      message: '',
      subject: ''
    };
  
    // Validate 'name' field
    if (!formPayload.name.trim()) {
      newErrors.name = 'Name is required';
      formIsValid = false;
    }
  
    // Validate 'email' field
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!formPayload.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if(!emailRegex.test(formPayload.email.trim())) {
      newErrors.email = 'Invalid email format';
      formIsValid = false;
    }
  
    // Validate 'phone' field
const phoneValue = formPayload.phone;
console.log("phoneNo"+phoneValue);
const phoneRegex = /^\d{10}$/; // Regular expression for exactly 10 digits

// if (!phoneValue || phoneValue.length === 0) {
//   newErrors.phone = 'Phone Number is required';
//   formIsValid = false;
// } else 
if ( phoneValue!="" && !phoneRegex.test(phoneValue)) {
  newErrors.phone = 'Phone is must be number';
  formIsValid = false;
}

  
    // Validate 'message' field
    // if (!formPayload.message.trim()) {
    //   newErrors.message = 'Message is required';
    //   formIsValid = false;
    // }
  
    // if (!formPayload.lookingFor) {
    //   newErrors.subject = 'Please select category';
    //   formIsValid = false;
    // }
  
    setErrors(newErrors);
  
    if (!formIsValid) {
      return;
    }
  
    try {
      setLoading(true);
      const response = await fetch(
        ApiPath+"/tqenquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formPayload),
        }
      );
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
  
        setFormVisible(false);
  
        setTimeout(() => {
          // setFormVisible(true);
          setLoading(false);
        }, 3000);
      } else {
        console.error("Error submitting the form", response.statusText);
        setFormError('An error occurred while submitting the form. Please try again later.');
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting the form", error);
      setFormError('An error occurred while submitting the form. Please try again later.');
      setLoading(false);
    }
  };

    const skills = [
      "Python ML Generative AI",
      "Data Analytics KNIME",
      "Java Spring Boot",
      "C# .NET Core",
      "Angular",
      "React JS",
      "React Native Mobile Programming",
      "Cybersecurity",
      "QA Manual + Automation"
    ];  

    const [activeTab1, setActiveTab1] = React.useState("tab1");
    const [tabIndex1, setTabIndex1] = React.useState(0);
         
    const handleTabClick1 = (index) => {
      setTabIndex1(index);
     
    };

    const data1 = [
      {
        count: "01",
        label: "Upgrading is a necessary activity nowadays, especially considering the cost-cutting scenarios across organizations.",
        value: "tab1",
        image: "",
        desc: ` `,
      },
  
      {
        count: "02",
        label: "Live interactive training is what you need—where a trainer guides you through live sessions.",
        value: "tab2",
        image: "",
        desc: ``,
      },
      {
        count: "03",
        label: "Online training is easy to attend from the comfort of your own space.",
        image: "",
        value: "tab3",
        desc: ``,
      },
      {
        count: "04",
        label: "Affordable cost and flexibility for the audience. The training can be selected for either 1 month or 3 months, based on your needs and availability.",
        image: "",
        value: "tab4",
        desc: ``,
      },
      {
        count: "05",
        label: "Placement assistance is an additional benefit, helping you apply for higher-package opportunities.",
        value: "tab5",
        image: "",
        desc: ``,
      },
    ];

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>{pageTitle}</title>
        <meta name="title" content={metaTitle} />

        {/* Meta Tags */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* Social Media Sharing */}
        <meta property="og:title" content={metaTitle || "Campaign - ThinkQuotient"} />
        <meta
          property="og:description"
          content="Discover ThinkQuotient's IT training campaigns. We offer tailored courses to help you excel in web, software, and app development with placement support."
        />
        <meta
          property="og:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />
        <meta property="og:url" content="https://www.thinkquotient.com/campaign" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle || "Campaign - ThinkQuotient"} />
        <meta
          name="twitter:description"
          content="Join ThinkQuotient's IT campaigns and explore training in web, software, and app development. Placement assistance guaranteed!"
        />
        <meta
          name="twitter:image"
          content="https://www.thinkquotient.com/assets/images/campaign-banner.png"
        />

        {/* Robots and Author */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Think Quotient" />

        {/* Google Tag Manager */}
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTNBVH9V');
        `}
        </script>

        {/* Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-143016865-1");
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BSFPPN5E9D');
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-574047969');
        `}
        </script>

        {/* Google Tag Manager noscript */}
        <noscript>{`
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KTNBVH9V"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      `}</noscript>
      </Helmet>



      <div className="overflow-hidden">


        {/* <div className="flex flex-col items-center py-14   px-6 campain-banner-sec ">

          
          <a href="https://www.thinkquotient.com" rel="noopener noreferrer">
            <img
              src="/assets/images/tq-logo.png"
              alt="Logo"
              className="absolute top-4 left-4 h-16 w-auto mb-4 "
            />
          </a>
          <h1
            className="text-base sm:text-lg font-normal container mx-auto text-center color-[#ffffff]"
          >
            <div>
              <p className="font-bold text-xl sm:text-2xl mt-6">
                Empowering Knowledge and Opportunities at ThinkQuotient{" "}
              </p>
            </div>
            <br /> At ThinkQuotient Software, we believe in empowering every
            learner. Our training programs are designed to shape your skills and open doors to endless
            IT opportunities. Best place for freshers to start their IT journey
          </h1>
        </div> */}
         {/* Banner Section */}
                
                  <div className="campaignpage-banner-sec flex flex-col xl:flex-row  items-center">
                  <a href="https://www.thinkquotient.com" rel="noopener noreferrer">
                    <img
                      src="/assets/images/tq-logo.png"
                      alt="Logo"
                      className="absolute top-4 left-4 h-16 w-auto mb-4 "
                    />
                  </a>
                    <div className=" w-3/4 xl:w-3/5 mt-24 sm:mt-36 xl:mt-[-4rem] container ml-10 text-left  text-white mx-auto">
                      <div
                        className=" mx-4 md:mx-0"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <h1 className="text-xl sm:text-4xl font-semibold mb-4">
                        Empowering Knowledge and Opportunities at ThinkQuotient{" "}
                        </h1>
                        <p className="text-base sm:text-xl mb-8">
                        At ThinkQuotient, we believe in empowering every
                        learner. Our training programs are designed to shape your skills and open doors to endless
                        IT opportunities. 
                        </p>
        
                      </div>
                    </div>
                  
                    <div className="hidden xl:flex w-1/2 xl:w-2/5 flex justify-center items-center ">
                       {/* Contact Form Section */}
                      {formVisible && (
                        <div className="top-10 2xl:right-20 right-10 w-fit  p-8 md:p-4 lg:p-4  contact-form-sec1 fade-in-right rounded-lg shadow-lg border border-blue-200 hover:shadow-xl transition-shadow duration-300">
                          <div>
                            <h2 className="text-2xl font-semibold mb-4">
                              Request a Callback
                            </h2>
                            <form className="banner-contact-form1" onSubmit={handleSubmit}>
                              {/* Add your form fields here */}
                              <div className="mb-1">
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  placeholder="Name *"
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  required
                                />
                                {errors.name && <p className="text-red-500">{errors.name}</p>}
                              </div>
                              <div className="mb-1">
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="Email *"
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  required
                                />
                                {errors.email && <p className="text-red-500">{errors.email}</p>}
                              </div>
                              <div className="mb-4">
                                <input
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  placeholder="Phone Number"
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  maxLength={10}
                                  // required
                                />
                                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                              </div>

                              {/* <div className="mb-4">
                                <select
                                  id="subject"
                                  name="subject"
                                  className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="" label="Select Category" />
              
                                  <option value="Fullstack Training">
                                  Fullstack Training
                                  </option>
                                  <option value="Corporate Training">Corporate Training</option>
                                
                                  <option value="Generative AI & KNIME">Generative AI & KNIME</option>
                                 
                                </select>
                                {errors.subject && <p className="text-red-500">{errors.subject}</p>}

                              </div> */}
                              <div className="mb-2">
                                <textarea
                                  id="message"
                                  name="message"
                                  placeholder="Write a message "
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  // required
                                />
                                {/* {errors.message && <p className="text-red-500">{errors.message}</p>} */}
                              </div>
                              {/* Add more fields as needed */}
                              <button type="submit" className="btn-style-1 w-3/4 sm:w-full py-2 my-4" disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit'}
                              </button>
                              {formError && <p className="text-red-500">{formError}</p>}
                            </form>
                          </div>
                        </div>
                      )}
                      {!formVisible && (
                        <div className="absolute top-40 2xl:right-20  p-8 contact-form-sec1 fade-in-right hidden md:block">
                          <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
                          <p className="text-lg mb-4">
                            Thank you for contacting us. We'll get back to you soon.
                          </p>
                          <button
                            onClick={() => setFormVisible(true)}
                            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-110 duration-300"
                          >
                            Close
                          </button>
                        </div>
                      )}
                    </div>
                   

                  </div>


                  {/* Form for smaller screens  */}
                  <div className="xl:hidden w-full py-8 contact-form-sec1 fade-in-right">
                  
                       {/* Contact Form Section */}
                      {formVisible && (
                        <div className=" p-8 md:p-4 lg:p-4  contact-form-sec1 fade-in-right rounded-lg shadow-lg border border-blue-200 hover:shadow-xl transition-shadow duration-300">
                          <div className="text-center justify-center">
                            <h2 className="text-2xl font-semibold mb-4">
                              Request a Callback
                            </h2>
                            <form className="banner-contact-form1 " onSubmit={handleSubmit}>
                              {/* Add your form fields here */}
                              <div className="mb-1">
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  placeholder="Name *"
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  required
                                />
                                {errors.name && <p className="text-red-500">{errors.name}</p>}
                              </div>
                              <div className="mb-1">
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="Email *"
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  required
                                />
                                {errors.email && <p className="text-red-500">{errors.email}</p>}
                              </div>
                              <div className="mb-4">
                                <input
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  placeholder="Phone Number"
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  maxLength={10}
                                  // required
                                />
                                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                              </div>

                              {/* <div className="mb-4">
                                <select
                                  id="subject"
                                  name="subject"
                                  className="w-full border-b border-stroke px-[5px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="" label="Select Category" />
                              
                                  <option value="Fullstack Training">
                                  Fullstack Training
                                  </option>
                                  <option value="Corporate Training">Corporate Training</option>
                                  <option value="Generative AI & KNIME">Generative AI & KNIME</option>
                                </select>
                                {errors.subject && <p className="text-red-500">{errors.subject}</p>}

                              </div> */}
                              <div className="mb-2">
                                <textarea
                                  id="message"
                                  name="message"
                                  placeholder="Write a message "
                                  className="w-full rounded-md p-2"
                                  onChange={handleChange}
                                  // required
                                />
                                {/* {errors.message && <p className="text-red-500">{errors.message}</p>} */}
                              </div>
                              {/* Add more fields as needed */}
                              <button type="submit" className="btn-style-1 w-3/4 sm:w-full py-2 my-4" disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit'}
                              </button>
                              {formError && <p className="text-red-500">{formError}</p>}
                            </form>
                          </div>
                        </div>
                      )}
                      {!formVisible && (
                        <div className="absolute top-40 2xl:right-20  p-8 contact-form-sec1 fade-in-right hidden md:block">
                          <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
                          <p className="text-lg mb-4">
                            Thank you for contacting us. We'll get back to you soon.
                          </p>
                          <button
                            onClick={() => setFormVisible(true)}
                            className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl transition-transform transform hover:scale-110 duration-300"
                          >
                            Close
                          </button>
                        </div>
                      )}
                    </div>
                   
                
                

        {/* Responsive Div with Grey Background */}
        {/* <div className="bg-gray-200 py-8 px-6 text-center rounded-md shadow-lg">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
              Still looking for an IT job?
            </h2>
            <p className="text-lg md:text-xl text-gray-600 mt-4">
              2000+ freshers placed across 450+ organizations. <br />
              <span className="text-blue-600 font-bold">You can be the next one.</span>
            </p>
          </div> */}
        {/* <div className="bg-gradient-to-r from-blue-50 via-blue-100 to-blue-50 py-10 px-8 text-center rounded-lg shadow-xl transform transition duration-300 hover:scale-105">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-800 flex items-center justify-center">
            <span className="mr-3">
              <img
                src="/assets/images/job-offer.png"
                alt="Job Icon"
                className="h-8 w-8 inline-block"
              />
            </span>
            Still looking for an IT job?
          </h2>
          <p className="text-lg md:text-xl text-gray-700 mt-6 leading-relaxed">

            <span className="text-blue-600 font-bold"> 2000+ freshers placed in 450+ top organizations.</span>
            <br />
            <span className="block mt-4 text-blue-500 text-lg font-bold ">You can be the next one!</span>
          </p>

        </div> */}
        
        {/* <div className="flex flex-col md:flex-row gap-8 px-0 md:px-4 lg:px-16 mb-4 bg-gradient-to-r from-blue-50 via-blue-100 to-blue-50 pb-8">
  <div className="w-full  lg:w-3/5 p-6 bg-white rounded-lg shadow-lg mt-8 border border-blue-200 hover:shadow-xl transition-shadow duration-300 md:mt-12 lg:mt-16 lg:pl-8">
    <h2 className="text-3xl font-bold font-serif text-blue-700 mb-4 text-center hover:text-blue-800 hover:scale-110 transition-transform duration-300 ">
      Grab this latest opportunity
    </h2>
    <div className="flex justify-center w-full mb-8" data-aos="fade-up" data-aos-duration="1000">
      <img
        src="/assets/images/Pine_Labs_Logo1.jpg"
        alt="PineLabs Logo"
        className="w-24 h-22 object-contain  hover:scale-110 transition-transform duration-300"
      />
    </div>

    <div className="flex flex-col lg:flex-row items-start gap-4 mb-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 text-lg text-gray-700 w-full">
        <div className="space-y-4" data-aos="fade-right" data-aos-duration="1000">
          <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300">
            <span className="font-semibold text-blue-600">Company:</span> PineLabs
          </li>
          <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300">
            <span className="font-semibold text-blue-600">Location:</span> Mumbai
          </li>
          <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300">
            <span className="font-semibold text-blue-600">Position:</span> Dot Net Developer Fresher
          </li>
        </div>
        <div className="space-y-4" data-aos="fade-left" data-aos-duration="1000">
          <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300">
            <span className="font-semibold text-blue-600">Work Style:</span> Offline
          </li>
          <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300">
            <span className="font-semibold text-blue-600">Technology:</span> Java/Dot Net
          </li>
          <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300">
            <span className="font-semibold text-blue-600">CTC:</span> 7 to 8 LPA
          </li>
        </div>
      </div>
    </div>

    <h3 className="text-xl font-semibold font-serif text-blue-600 mt-6 border-b border-blue-200 pb-1 hover:text-blue-800 hover:scale-105 transition-transform duration-300">
      Job Description
    </h3>
    <p className="text-base font-semibold mt-2 text-gray-600 hover:text-blue-600 hover:scale-105 transition-transform duration-300">
      Developer (Fresher)
    </p>

    <h3 className="text-xl font-semibold font-serif text-blue-600 mt-6 border-b border-blue-200 pb-1 hover:text-blue-800 hover:scale-105 transition-transform duration-300">
      Required Skills and Qualifications
    </h3>
    <ul className="list-disc pl-6 mt-3 space-y-3 text-base text-gray-700" data-aos="fade-right" data-aos-duration="1000">
      <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300 font-medium">Strong OOPS concepts.</li>
      <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300 font-medium">SQL Concepts.</li>
      <li className="vertical-line list-none hover:text-blue-600 hover:scale-105 transition-transform duration-300 font-medium">Problem solving.</li>
    </ul>

    <p className="mt-6 text-lg text-blue-700 font-semibold text-center hover:text-blue-800 hover:scale-105 transition-transform duration-300">
      For more details, please fill the form.
    </p>
  </div>

  <div className="w-full lg:w-2/5 mt-6 md:mt-12 lg:mt-16">
    <CallBackform />
  </div>
</div> */}


<div className="flex flex-col items-center py-14  fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl xl:text-4xl font-serif   ">
                Training Programs with ThinkQuotient {" "}
              </p>
            </div>
          </h1>
</div>
<div className="mx-0 xl:mx-4 mb-4 md:mb-8">
<div className="skills-container  bg-gradient-to-br from-blue-300 via-white to-teal-200  p-8 rounded-lg shadow-xl  mx-auto text-center"
    data-aos="fade-up"
    data-aos-duration="1000">

      <h2 className="skills-header text-gray-800 font-bold text-3xl mb-6 lg:mb-12">Training Expertise</h2>
      <div className="skills-list flex justify grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 justify-between">
        {skills.map((skill, index) => (
          <div key={index} className="skill-item bg-gradient-to-br from-gray-400 to-blue-600 p-6 rounded-lg shadow-lg  w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg min-h-[120px] flex justify-center items-center">
            <p className="text-base md:text-lg font-semibold text-white text-center">{skill}</p>
          </div>
        ))}
      </div>
    </div>
  </div>

  

        <div className="flex flex-col items-center pt-14   fade-in-down px-6">
                 <h1
                   className="text-lg font-normal container mx-auto text-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                 >
                   <div>
                     <p className=" text-2xl md:text-3xl xl:text-4xl font-bold font-serif  ">
                        Benefits  {" "}
                     </p>
                   </div>
                 </h1>
        </div>
       
        <div className="flex flex-col lg:flex-row mt-6"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                 >
        <div className="w-full lg:w-[60%] container  mx-auto py-14 lg:py-8 px-4 hidden  lg:block"
                 ref={tabRef} >
              <ul>
                  {data1.map(({ label }, index) => (
                    <li
                      key={index}
                      className="flex items-start py-2 group transition-transform duration-200 ease-in-out hover:scale-105"
                    >
                      <span
                        className="mt-2 w-[3px] h-[30px] bg-[#01a8ef] transition-all duration-200 ease-in-out group-hover:w-[5px] group-hover:h-[50px] group-hover:bg-blue-500"
                      ></span>
                      <p
                        className="ml-4 font-semibold text-lg md:text-xl mb-2 text-gray-700 transition-colors duration-200 ease-in-out group-hover:text-blue-500"
                      >
                        {label}
                      </p>
                    </li>
                  ))}
                </ul>
               </div>
       
       
       
               <div className="container mx-auto py-10 px-6 md:px-10 lg:px-4 md:block lg:hidden">
                 <ul>
                  {data1.map(({ label }, index) => (
                    <li
                      key={index}
                      className="flex items-start py-2 group transition-transform duration-200 ease-in-out hover:scale-110"
                    >
                      <span
                        className="mt-2 w-[3px] h-[30px] bg-[#01a8ef] transition-all duration-200 ease-in-out group-hover:w-[5px] group-hover:h-[50px] group-hover:bg-blue-500"
                      ></span>
                      <p
                        className="ml-4 font-semibold text-lg md:text-xl mb-2 text-gray-700 transition-colors duration-200 ease-in-out group-hover:text-blue-500"
                      >
                        {label}
                      </p>
                    </li>
                  ))}
                </ul>
               </div>

               <div className="w-full lg:w-[40%] p-4 flex items-center justify-center" 
               data-aos="fade-left"
               data-aos-duration="1000">
                  <img
                    src="/assets/images/person-working-html-computer.jpg"
                    alt="Student"
                    className="w-full h-auto text-center transform  hover:scale-105 transition duration-300 ease-in-out"
                  />
                </div>
            </div>




{/* <div className="flex flex-col xl:flex-row gap-6 mx-4 lg:gap-10 mt-6 mb-8">
 
  <div
    className="relative bg-gradient-to-br from-blue-500 to-purple-400 text-white rounded-lg shadow-xl p-8 xl:w-1/2 hover:scale-105 transition-transform duration-300"
    data-aos="fade-right"
    data-aos-duration="1000"
  >
    
    <h3 className="text-3xl font-bold mb-4">
      Intelligence-based Full Stack Program
    </h3>
    <p className="text-white text-opacity-90 mb-6 leading-relaxed">
      Dive into our comprehensive full-stack program tailored to enhance your
      intelligence-based programming skills. Build innovative, scalable
      solutions with cutting-edge technologies.
    </p>
  
  </div>

  
  <div
    className="relative bg-gradient-to-br from-green-500 to-teal-400 text-white rounded-lg shadow-xl p-8 xl:w-1/2 hover:scale-105 transition-transform duration-300"
    data-aos="fade-left" 
    data-aos-duration="1000"
  >
   
    <h3 className="text-3xl font-bold mb-4">
      Gen AI & Germany-based KNIME Program
    </h3>
    <p className="text-white text-opacity-90 mb-6 leading-relaxed">
      Join the industry-leading training program focused on Generative AI and
      KNIME. Designed for data enthusiasts and professionals, this program
      provides advanced insights into data analytics and AI applications.
    </p>
    
  </div>
</div>


        <div className="flex flex-col xl:flex-row gap-4 mx-4 lg:gap-8">
          <div className="w-full xl:w-1/2 h-auto" data-aos="fade-right" data-aos-duration="1000">
            <img
              src="/assets/images/internp1.png"
              alt="Fullstack Training Program"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full xl:w-1/2 h-auto" data-aos="fade-left" data-aos-duration="1000">
            <img
              src="/assets/images/Generativ_AI_Knime.png"
              alt="Generative AI & KNIME Training Program"
              className="w-full h-full object-cover"
            />
          </div>
        </div> */}

        <div className="flex flex-col items-center py-14  fade-in-down px-6">
          <h1
            className="text-lg font-normal container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div>
              <p className="font-bold text-2xl xl:text-4xl font-serif   ">
                Technology Training Programs with Live Projects{" "}
              </p>
            </div>
          </h1>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="m-0 flex flex-col xl:flex-row  mx-0 lg:mx-4 px-0 lg:px-4 gap-x-8 justify-between">
            {/* Tabs Section */}
            <div className="">
            <div className="flex flex-col items-center py-14   fade-in-down px-6">
              <h1
                className="text-lg font-normal container mx-auto text-center"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div>
                  <p className="text-3xl sm:text-5xl xl:text-3xl font-bold ">
                    Features{" "}
                  </p>
                </div>
              </h1>
            </div>
              <ul className="list-none mx-6 md:mx-10" data-aos="fade-right"
              data-aos-duration="1000">
                {data.map(({ label }, index) => (
                  <li
                    key={index}
                    className="flex items-center my-8 transition-transform duration-200 ease-in-out hover:scale-105 cursor-pointer"
                  >
                    <span
                      className="w-[3px] h-[30px] bg-[#01a8ef] mr-4 transition-all duration-200 ease-in-out hover:w-[5px] hover:h-[40px] hover:bg-blue-500"
                    ></span>
                    <p className="font-semibold text-lg md:text-xl text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500">
                      {label}
                    </p>
                  </li>
                ))}
              </ul>
            {/* Blinking button at the bottom */}
            {/* <div className="mt-12 flex justify-center">
              <button
                className="btn-style-1 text-xl mt-2 p-4  animate-blink hover:animate-none "
                onClick={() => downloadPDF(pdfPaths)}
              >
                Download Brochure
              </button>
            </div> */}
          </div>
         
          <div
            className="xl:w-1/2 md:p-10 p-10 flex flex-col justify-center bg-white"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
             <div>
             <h2 className="text-3xl sm:text-5xl xl:text-3xl font-bold text-center mb-16 mt-4">
              Technologies
            </h2>
                </div>
          
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 gap-y-8 ">
              {/* Image 1 */}
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/java.svg"
                    alt="Java"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">Java</p>
              </div>

              {/* Image 2 */}
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/dotnet.1024x1024 (1).png"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">DotNet</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/c.svg"
                    alt="DotNet"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">C++</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/spring.svg"
                    alt="SpringBoot"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">SpringBoot</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/hibernate-icon.svg"
                    alt="Hibernate"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">Hibernate</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/sql.svg"
                    alt="SQL"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">SQL</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/angular.svg"
                    alt="Angular"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">Angular</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/react.svg"
                    alt="React"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">React</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/KnimeImg.png"
                    alt="KNIME"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">KNIME</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/python.svg"
                    alt="Python"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">Python</p>
              </div>

              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/data-science.svg"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">Data Science</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-100 rounded-lg w-24 h-24 flex items-center justify-center p-4 shadow-md transition transform duration-300 hover:scale-110">
                  <img
                    src="/assets/images/artificial-intelligence.png"
                    alt="Data Science"
                    className="w-auto h-auto max-w-[80px] max-h-[80px] object-contain"
                  />
                </div>
                <p className="mt-4 text-center text-xl">GenAI</p>
              </div>
              
            </div>
            
            
          </div>
         {/* <div
            className="lg:w-1/2 border-2 border-gray-200 "
            data-aos="fade-left"
            data-aos-duration="1000"
            id="web-development"
          >
            <img
              src="/assets/images/internp.png"
              className="object-contain w-full h-full"
            />
          </div> */}
        </div>





        <Clientele />
        {/* <SuccessStoryCarousel /> */}
        <StudentTestimonials />
        {/* <Footer /> */}
        {/* <StickyElements /> */}

         <div className="bottom-footer md:flex text-white  justify-center  px-4 md:px-20 italic py-6 text-center">
                <p>&copy; Copyright 2025 by ThinkQuotient </p>  
        </div>
      </div >
    </>
  );
};

export default CampaignPage;
